import React, { useMemo, useContext, useCallback } from 'react';
import { Select, MenuItem, OutlinedInput, Grid } from '@material-ui/core';
import _ from 'lodash';
import { FormTextField } from 'fitbud/components/form-fields';
import clsx from 'clsx';
import GroupClassSelector from 'fitbud/components/catalogSelector/groupClassesSelector';
import ChipGroups from 'fitbud/components/catalog-filter/chipGroups';
import NumUnitInput from 'fitbud/components/numUnitInput';
import { usePicker, useToggle } from 'fitbud/hooks/form';
import { AccessContext } from 'fitbud/providers/access-provider';

const CLASSKEY = 'classes';
const GC_ACCESS_TYPES = [
  { label: 'Selected Class', value: 'partial' },
  { label: 'All Classes', value: 'all' },
  { label: 'All Classes Except', value: 'except_all' },
];

//just rendered components for managing group classAccess.
const GcAccess = (props) => {
  const {
    setDirty,
    classes = {},
    disabled,
    withCalls = false,
    noOfCalls,
    onChangeCalls,
    errors={},
    noCallsId = "accessValue"
  } = props;
  const {getAccessType, updateTargetAccess, classCache} = useContext(AccessContext);
  const [openGcSelector, toggleGcSelector] = useToggle(false);

  const [selected, gcChipData, addMore] = useMemo(() => {
    const list = getAccessType({key: CLASSKEY, current: false}).pop();
    const out = [], chips = [];
    list.forEach(_id => {
      const data = classCache[_id] || {};
      out.push({_id, data});
      chips.push({value: _id, label: data.ref_name || 'Loading ...', onDelete: () => {
        updateTargetAccess(CLASSKEY, {pull: _id});
        setDirty(true);
      }});
    });
    return [out, chips, disabled ? null : {onClick: toggleGcSelector}];
  }, [getAccessType, updateTargetAccess, classCache, disabled, toggleGcSelector, setDirty]);

  const onTypeChange = useCallback((type) => {
    setDirty(true);
    switch (type) {
      case 'all':
      case 'except_all':
        updateTargetAccess(CLASSKEY, {ids: ['all']});
        break;
      default: // partial case
        updateTargetAccess(CLASSKEY, {auto: true});
        break;
    }
  }, [updateTargetAccess, setDirty])
  const [accessType, setAccessType] = usePicker(getAccessType({key: CLASSKEY})[0], onTypeChange);

  const addSelectedGcs = (selectedValues) => {
    const gcIds = selectedValues.map((gc) => gc._id);
    updateTargetAccess(CLASSKEY, {push: gcIds});
    toggleGcSelector();
    setDirty(true);
  };

  return (
    <div className={clsx(classes.root, 'w-100')}>
      <Grid container>
        <Grid item xs={12} md={6} className={clsx(withCalls && "pr-20")}>
          <FormTextField fullWidth label="Access Control">
            <Select
              disabled={disabled}
              required
              variant="outlined"
              name="gc_access_type"
              fullWidth
              value={accessType}
              className={clsx('mb-0')}
              onChange={setAccessType}
              input={<OutlinedInput classes={{ root: 'medium', input: 'size_16_500 select-medium' }} />}>
              {GC_ACCESS_TYPES.map((item, index) => {
                  return (
                    <MenuItem value={item.value} key={item.value + index}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormTextField>
        </Grid>
        {withCalls && (
          <Grid item xs={12} md={6}>
            <NumUnitInput
              id= {noCallsId}
              disabled={disabled}
              label="Number of sessions"
              value={noOfCalls}
              error={!!_.get(errors, noCallsId)}
              helperText={_.get(errors, noCallsId)}
              onChange={onChangeCalls}
              variant="outlined"
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </Grid>
        )}
        {(accessType === 'partial' || accessType === 'except_all') && (
          <Grid item xs={12}>
            <ChipGroups
              noBorder addMore={addMore}
              fields={gcChipData}
              componentState={[]}
              isMulti={true}
              classes={{ label: 'font_13_600 text-dark-grey'}}
            />
          </Grid>
        )}
        {openGcSelector && (
          <GroupClassSelector
            handleAdd={addSelectedGcs}
            title="Select Class"
            isBack
            open
            selected={selected}
            handleClose={() => toggleGcSelector(false)}
          />
        )}
      </Grid>
    </div>
  );
};

export default GcAccess;
